export default [
  {
    title: 'Live Streams',
    icon: 'MonitorIcon',
    route: 'home',
  },
  {
    title: 'Categories',
    icon: 'GridIcon',
    route: 'categories',
  },
  // {
  //   title: 'BrimePro™',
  //   icon: 'BoldIcon',
  //   route: 'BrimePro',
  // },
]
