<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow main-menu--marginless"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <div
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
      <vertical-nav-menu-items
        :items="following"
        class="navigation navigation-main"
      />
      <vertical-nav-menu-items
        :items="bottomItems"
        class="navigation navigation-main"
      />
    </div>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import bottomItems from '@/navigation/vertical/footer'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      following: [
        {
          header: 'Following',
          class: 'sidebar-follow-text',
        },
      ],
    }
  },
  mounted() {
    // eslint-disable-next-line
    const hashCode = s => s.split('').reduce((a, b) => { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0)
    const updateSidebarFollowing = async () => {
      const navheader = document.querySelector('.navigation-header')
      if (this.$auth.user) {
        const following = await this.userGetFollowing()
        const followingData = following
        const newFollowingData = []
        followingData.forEach(follow => {
          newFollowingData.push({
            title: follow.slug,
            avatar: follow.slug,
            follow,
            icon: '',
            route: { path: `/${follow.slug}` },
          })
        })
        newFollowingData.sort((a, b) => {
          // eslint-disable-next-line
          const aHash = hashCode(a.follow.slug)
          // eslint-disable-next-line
          const bHash = hashCode(b.follow.slug)
          return aHash - bHash
        })
        this.following = [{
          header: 'Following',
          class: 'sidebar-follow-text',
        }, ...newFollowingData]
        if(navheader) navheader.style.display = ''
      } else {
        if(navheader) navheader.style.display = 'none'
      }
    }
    updateSidebarFollowing()
    setInterval(updateSidebarFollowing, 20000)
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)
    const { skin } = useAppConfig()
    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)
    provide('isMouseHovered', isMouseHovered)
    const perfectScrollbarSettings = {
      wheelSpeed: 1,
      wheelPropagation: false,
      minScrollbarLength: 20,
    }
    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      navMenuItems,
      bottomItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      // Shadow Bottom
      shallShadowBottom,
      // Skin
      skin,
      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>
<style>
.nav-toggle{
display: none;
}

.main-menu--marginless .navigation-header {
  margin-top: .75rem !important;
  margin-bottom: 0rem !important;
}
</style>
<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
