<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav
      v-if="!$auth.loading"
      class="nav align-items-center ml-auto"
    >
      <b-button
        v-if="!$auth.isAuthenticated"
        variant="outline-primary"
        size="sm"
        @click="$auth.loginWithRedirect();"
      >
        <b>Login</b>
      </b-button>
      <b-button
        v-if="!$auth.isAuthenticated"
        variant="primary"
        size="sm"
        href="/register"
      >
        Sign Up
      </b-button>
      <b-nav-item-dropdown
        v-if="$auth.isAuthenticated"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.display_name }}
            </p>
            <span
              class="user-status"
              v-if="!userExtra.is_staff == true"
            >{{ 'Creator' }}</span>
            <span
              class="user-status"
              v-if="userExtra.is_staff == true"
            >{{ 'Staff' }}</span>
          </div>
          <b-avatar
            size="40"
            :src="userAvatar"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'account-settings' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Settings</span>
        </b-dropdown-item>
        <!-- <b-dropdown-item
          :to="{ name: 'billing' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="CreditCardIcon"
            class="mr-50"
          />
          <span>Billing</span>
        </b-dropdown-item> -->
        <b-dropdown-item
          :href="'/'+$auth.user.nickname"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="VideoIcon"
            class="mr-50"
          />
          <span>My Channel</span>
        </b-dropdown-item>
        <b-dropdown-item
          to="/studio"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="BarChart2Icon"
            class="mr-50"
          />
          <span>Creator Studio</span>
        </b-dropdown-item>
        <!-- <b-dropdown-item
          :to="'/'+$auth.user.nickname + '/clips'"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="ScissorsIcon"
            class="mr-50"
          />
          <span>My Clips</span>
        </b-dropdown-item> -->
        <b-dropdown-divider v-if="userExtra.is_staff == true" />
        <b-dropdown-item
          :to="'/admin'"
          link-class="d-flex align-items-center"
          v-if="userExtra.is_staff == true"
        >
          <feather-icon
            size="16"
            icon="ShieldIcon"
            class="mr-50"
          />
          <span>Mod Tools</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          :to="'/billing'"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="CreditCardIcon"
            class="mr-50"
          />
          <span>Subscriptions</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line
const axios = require('axios')

export default {
  components: {
    BButton,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    function parseJwt(token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))
      return JSON.parse(jsonPayload)
    }
    if (localStorage.getItem('accessToken')) {
      const userData = parseJwt(localStorage.getItem('accessToken'))
      userData.role = 'user'
      // eslint-disable-next-line
      userData.ability = [{ action: 'manage', subject: 'all' }]
      return {
        userData,
        avatarText,
        userAvatar: '',
      }
    }
    return {
      userData: {},
      userExtra: {},
      avatarText: {},
      userAvatar: '',
    }
  },
  async mounted() {
    if (this.$auth.user) {
      this.userData = await this.getUser()
      this.userAvatar = `https://assets.brimecdn.com/users/${this.userData.xid}/avatar`
    }
    if (this.$auth.user) {
      let user_xid = this.$auth.user.sub.replace('auth0|', '')
      const user = await axios.get(`https://api.brime.tv/v1/users/${user_xid}`)
      this.userExtra = user.data
      console.log(this.user)
    }
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
<style scoped>
.btn {
 margin-left: 5px;
}
</style>
