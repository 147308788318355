export default [
  {
    header: 'Platform',
    class: 'sidebar-follow-text',
  },
  // {
  //   title: 'Leaderboard',
  //   icon: 'BarChart2Icon',
  //   route: 'home',
  // },
  {
    title: 'Platform Stats',
    icon: 'ActivityIcon',
    route: 'stats',
  },
  {
    title: 'Support',
    icon: 'HelpCircleIcon',
    href: 'https://support.brimelive.com/',
  },
  {
    title: '',
    icon: '',
  },
  {
    title: 'Privacy Policy',
    icon: 'BookmarkIcon',
    route: 'PrivacyPolicy',
  },
  {
    title: 'Terms of Service',
    icon: 'BookIcon',
    route: 'ToS',
  },
  
]
